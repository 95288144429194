<template>
  <div>
    <h5 class="p-t-20 bold p-b-10 auth-title">
<!--      {{ $t("auth.login page") }}-->
      Login
    </h5>
    <validation-observer
      ref="form_create_entry"
      v-slot="{ passes, valid, validated }"
    >
      <form novalidate autocomplete="off" @submit.prevent="passes(login)">
        <app-header-notice ref="notice"></app-header-notice>
        <label class="m-b-5 input-label control-label">
<!--          {{ $t("common.mail address") }}-->
          Email
          <span class="app-require">＊</span>
        </label>
        <app-input
          inputStyle="normal"
          name="login_email"
          type="email"
          :rules="VALIDATE.V_EMAIL_REQUIRED"
          v-model="email"
        ></app-input>
        <label class="m-b-5 m-t-10 input-label control-label">
<!--          {{ $t("common.password") }}-->
          Password
          <span class="app-require">＊</span>
        </label>
        <app-password
          name="password"
          type="password"
          rules="required"
          v-model="password"
        ></app-password>
        <br/><br/>
<!--        <div class="text-center">-->
<!--          <router-link-->
<!--            class="forgot-password color-active"-->
<!--            :to="{ name: forgotPasswordRoute }"-->
<!--          >-->
<!--&lt;!&ndash;            {{ $t("auth.forgot_password_label") }}&ndash;&gt;-->
<!--            Forgot Password-->
<!--          </router-link>-->
<!--        </div>-->
        <div class="text-center">
          <button class="app-btn app-btn-blue" type="submit">
<!--            {{ $t("auth.login") }}-->
            Login
          </button>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>
  import {AUTH} from "@constants";

  import adminRoute from '../../routes/routes';

  export default {
  name: "LoginPage",

  data() {
    let resetPassword =
      AUTH[this.$store.state.auth.currentAuth.role].routes.reset_password;
    let forgotPassword =
      AUTH[this.$store.state.auth.currentAuth.role].routes.forgot_password;
    return {
      resetPasswordRoute: resetPassword,
      forgotPasswordRoute: forgotPassword,
      email: this.$route.query.email,
      password: null,
      error: false,
    };
  },

  mounted() {
    if (this.$route.query.verified === "yes") {
      this.$refs.notice.setSuccess(this.$t("auth.verify ok"));
    }
    if (this.$route.query.changePass === "yes") {
      this.$refs.notice.setSuccess(this.$t("auth.reset pass ok"));
    }
  },

  methods: {
    async login() {
      let role = AUTH[this.$store.state.auth.currentAuth.role].role_id;
      let homeRoute =
        AUTH[this.$store.state.auth.currentAuth.role].routes.dashboard;
      let A_Bizcontact_List = "ADMIN.ANNOUNCEMENTS_LIST";
      let request = {
        email: this.email,
        password: this.password,
        role: role,
      };
      let response = await this.$request.post(
        this.ENDPOINT.AUTH_LOGIN,
        request
      );

      if (!response.hasErrors()) {
        this.$store.commit(`auth/updateAuth`, {
          user: response.data.data,
          token: response.headers.authorization,
          expire_time: response.headers["token-expire-time"],
        });
        let prevRouteName = this.$store.state.setting.prevRouter.name;
        if (
          prevRouteName &&
          prevRouteName !== this.resetPasswordRoute &&
          prevRouteName !== this.forgotPasswordRoute &&
          prevRouteName !== homeRoute
        ) {
          let path = this.getRoutePathFromInstance(prevRouteName)
          if (path) {
            window.location.href =  this.getRoutePathFromInstance(prevRouteName)
          } else {
            this.$router.push(this.$store.state.setting.prevRouter);
            this.__redirect(prevRouteName);
          }

        } else {
          window.location.href = "/admin/announcements";
        }
      } else {
        this.$refs.notice.setError(
          response.data.msg ? response.data.msg : this.$t("common.error")
        );
      }
    },
    getRoutePathFromInstance(instance) {
      let route = adminRoute.find(({name}) => name === instance)
      let path = '';
      if (!path)  {
        let children = adminRoute[1].children
        route = children.find(({name}) => name === instance)
        path = route.path
      } else {
        path = route.path
      }
      return path
    }
  },
};
</script>

<style scoped lang="scss">
  @import "@assets/pages/login.scss";
</style>
